import 'stylesheets/c4real/custom/107/index.scss';
import 'javascripts/c4real/index';
import isMobile from 'libs/Utils/isMobile';

window.couponTypeMaxFontSize = isMobile.mobile() ? 22 : 22;
window.couponAmountMaxFontSize = isMobile.mobile() ? 30 : 36;
window.largeCouponAmountMaxFontSize = isMobile.mobile() ? 30 : 44;
window.largeCouponTypeMaxFontSize = isMobile.mobile() ? 22 : 26;
window.largeCouponActionBtnMaxFontSize = isMobile.mobile() ? 14 : 18;
window.largeCouponTypeDetectMultiLine = true;
